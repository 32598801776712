//initialState
export const initialState = {
    isSignedin: false,
    isConfirmedSignup: false,
    username: "",
    displayUsernamePage: false,
    role: ''
}




//actionTypes
const SET_ISSIGNEDIN = "SET_ISSIGNEDIN";
const SET_ISCONFIRMEDSIGNUP = "SET_ISCONFIRMEDSIGNUP";
const SET_USERNAME = "SET_USERNAME";
const SET_ROLE = "SET_ROLE";
const SET_DISPLAY_USERNAME_PAGE = "SET_DISPLAY_USERNAME_PAGE";




//actions
export const settingISSIGNEDIN = (bool) => {
    return {
        type: SET_ISSIGNEDIN,
        payload: bool
    }
}
export const settingISCONFIRMEDSIGNUP = (bool) => {
    return {
        type: SET_ISCONFIRMEDSIGNUP,
        payload: bool
    }
}
export const settingUSERNAME = (bool) => {
    return {
        type: SET_USERNAME,
        payload: bool
    }
}
export const settingROLE = (role) => {
    return {
        type: SET_ROLE,
        payload: role
    }
}
export const settingDISPLAY_USERNAME_PAGE = (bool) => {
    return {
        type: SET_DISPLAY_USERNAME_PAGE,
        payload: bool
    }
}




//reducer
/*https://medium.com/@akrush95/global-cached-state-in-react-using-hooks-context-and-local-storage-166eacf8ab46*/
export function reducer(state = initialState, action) {
    if (state === null) {
        localStorage.removeItem("state");
        return initialState;
    }


    switch (action.type) {
        case SET_ISSIGNEDIN:
            return {
                ...state,
                isSignedin: action.payload
            }

        case SET_ISCONFIRMEDSIGNUP:
            return {
                ...state,
                isConfirmedSignup: action.payload
            }

        case SET_USERNAME:
            return {
                ...state,
                username: action.payload
            }

        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            }

        case SET_DISPLAY_USERNAME_PAGE:
            return {
                ...state,
                displayUsernamePage: action.payload
            }

        default:
            return state;
    }
}